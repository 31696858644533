<template>
  <HADSButton
    is="label"
    class="chips"
    :for="id"
    outlined
    rounded
    type="button"
    :is-active="isSelected"
    @click="onClick()"
  >
    <input
      :id="id"
      v-model="modelValue"
      :value="value"
      :type="type"
    />
    <slot />
    <HaIcon
      v-if="isSelected"
      class="chips-icon"
      :icon="faRemove"
    />
  </HADSButton>
</template>

<script setup lang="ts">
import { faRemove } from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '@ha/components-v3'
import HADSButton from '@/domains/design_system/atoms/HADSButton/HADSButton.vue'
import { computed } from 'vue'

const modelValue = defineModel<string | number | any | any[]>()

interface HADSChipsProps {
  id: string
  value: any
  type?: 'radio' | 'checkbox'
}

const props = withDefaults(defineProps<HADSChipsProps>(), {
  type: 'radio'
})

const emit = defineEmits(['remove', 'update:model-value'])

const isSelected = computed(() => {
  if (props.type == 'checkbox') {
    if (typeof modelValue.value === 'object') {
      return modelValue.value?.includes(props.value)
    } else if (typeof modelValue.value === 'boolean') {
      return modelValue.value
    } else {
      return (
        JSON.stringify(modelValue.value) ===
        JSON.stringify(props.value)
      )
    }
  } else {
    return (
      JSON.stringify(modelValue.value) === JSON.stringify(props.value)
    )
  }
})

const onClick = () => {
  if (isSelected.value) {
    if (props.type == 'radio') {
      emit('update:model-value', undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
.chips {
  position: relative;
  padding: var(--hads-spacing-200) var(--hads-spacing-300);

  .chips-icon {
    @include mediaQuery(800) {
      transition: var(--hads-transition-simple);

      &:hover {
        transform: scale(1.25);
      }
    }
  }

  > input {
    position: absolute;
    display: block;
    inset: 0;
    opacity: 0;
    margin: 0;
  }
}
</style>
